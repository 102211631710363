import React from "react";
import PropTypes from "prop-types";
import Sticky from "react-sticky-el";

import { Helmet } from "react-helmet";

import Theme, { theme } from "@pagerland/themes/src/WebApp";
import {
  Navbar,
  Copyright,
  Footer,
  Welcome,
  // Brands,
  About,
  Features,
  Screenshots,
  // Clients,
  // Cta,
  // Pricing,
} from "@pagerland/themes/src/WebApp/containers";

import preview from "@pagerland/themes/src/WebApp/assets/preview.png";
import data from "@pagerland/themes/src/WebApp/data/en";

import SEO from "../components/SEO";

const WebApp = ({ url }) => (
  <Theme>
    <Helmet>
      <link href={theme.typography.googleFont} rel="stylesheet" />
      <meta name="theme-color" content={theme.colors.primary} />
      <meta property="og:image" content={`${url}${preview}`} />
    </Helmet>
    <SEO
      title="Gorila Digital"
      lang="en"
      description="Gorila Digital. Your new Software Advisors! Here in Gorila Digital, we are passionate about software and we want you to feel the same. Relax and let us work on your idea."
    />

    <Sticky style={{ zIndex: 999, position: "relative" }}>
      <Navbar links={data.navbar.links} actions={data.navbar.actions} />
    </Sticky>

    <Welcome name="" title={data.welcome.title} text={data.welcome.text} />
    {/* <Brands name="" /> */}

    <About name="about" sections={data.about.sections} />
    <Features
      name="features"
      title={data.features.title}
      text={data.features.text}
      features={data.features.features}
    />
    <Screenshots
      name="screens"
      title={data.screenshots.title}
      text={data.screenshots.text}
      screenshots={data.screenshots.screenshots}
    />

    {/* <Clients name="clients" /> */}

    <Footer
      name="contact"
      section1={data.footer.section1}
      section3={data.footer.section3}
    />
    <Copyright />
  </Theme>
);

WebApp.propTypes = {
  url: PropTypes.string,
};

WebApp.defaultProps = {
  url: "https://www.goriladigital.es",
};

export default WebApp;
